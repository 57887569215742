.bp3-navbar-heading {
  a {
    color: $pt-heading-color;

    &:hover {
      color: $pt-text-color-muted;
      text-decoration: none;
    }
  }
}
