h1 {
  font-size: 2rem;
  margin: 25px 0;

  &:first-child {
    margin-top: 0;
  }
}

h2 {
  font-size: 1.5rem;
}

p {
  margin: 20px 0;

  &:last-child {
    margin-top: 0;
  }
}
