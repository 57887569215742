.user-header {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $pt-divider-black;
  width: 100%;
  padding-bottom: 2em;

  .user-content {
    width: 60%;
    display: flex;
  }

  .user-image {
    width: 80px;
    height: 80px;
    border: 1px solid $pt-dark-divider-black;
    border-radius: 3px;
    margin-right: 1.5em;
    display: inline-block;
  }

  .user-info {
    display: inline-block;
  }

  .user-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.25em;
    display: flex;
    align-items: center;

    .bp3-tag {
      margin-left: 0.5em;
    }
  }

  .header-btn {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
