body {
  background-color: $pt-app-background-color;
  color: $pt-text-color;
  font-family: $font;
  font-size: 13px;
  margin: 0;
  padding-top: $pt-navbar-height;
  line-height: $pt-line-height;

  &.login {
    background: #30404d;
    color: #fff;
    padding-top: 0;
  }

  *,
  *::before,
  *::after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
